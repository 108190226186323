import { Flex } from '@mantine/core';
import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { autorun, runInAction, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Account, Billable, Campaign, Event, Targeting } from '@feathr/blackbox';
import { CampaignClass, CampaignState, getMinDuration, getMinStartDate } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  ButtonValid,
  CardV2 as Card,
  Collapse,
  DatePicker,
  EAlertV2Type as EAlertType,
  EmptyState,
  Fieldset,
  Form,
  NumberInput,
} from '@feathr/components';
import { useFlags, useStore, useUser } from '@feathr/extender/state';
import {
  cssVar,
  flattenErrors,
  moment,
  momentToDate,
  TimeFormat,
  timezoneAbbr,
} from '@feathr/hooks';

import BaseBidSelect from '../AdCampaignEditOptimizationStep/BaseBidSelect';
import FreqCapSelect from '../AdCampaignEditOptimizationStep/FreqCapSelect';
import FreqPeriodSelect from '../AdCampaignEditOptimizationStep/FreqPeriodSelect';
import { getTargetables, getTargetSegments } from '../CampaignEditStepTwo';
import {
  getAudienceSize,
  getMinBudget,
  getRecommendedBid,
  getRecommendedBudget,
  getRecommendedImpressions,
  validateStepBudget,
} from './AdCampaignEditBudgetStep.utils';
import PaymentDetails from './PaymentDetails';
import RecommendedBudget from './RecommendedBudget';

import * as styles from './AdCampaignEditBudgetStep.css';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  account: Account;
  event: Event;
  billable?: Billable;
  campaign: Campaign;
  targetings: IObservableArray<Targeting>;
}

const NextStepButton = observer(
  ({ campaign, event, billable, account, onNext }: Omit<IProps, 'onPrev' | 'targetings'>) => {
    const flags = useFlags();
    const user = useUser();

    const validationErrors = validateStepBudget(
      campaign,
      event,
      billable,
      account,
      user,
      flags.noMaxBudget,
    );
    return (
      <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
        Next
      </ButtonValid>
    );
  },
);

function AdCampaignEditBudgetStep({
  account,
  campaign,
  event,
  billable,
  onNext,
  onPrev,
  targetings,
}: Readonly<IProps>): JSX.Element {
  const { Segments, Targetables } = useStore();
  const flags = useFlags();
  const user = useUser();
  const { t } = useTranslation();

  const segments = getTargetSegments(targetings, Segments);
  const targetables = getTargetables(targetings, Targetables);
  const validationErrors = validateStepBudget(
    campaign,
    event,
    billable,
    account,
    user,
    flags.noMaxBudget,
  );
  const monetization = campaign.get('parent_kind') === 'partner';
  const dateStart = moment.utc(campaign.get('date_start'), TimeFormat.isoDateTime);
  const dateEnd = moment.utc(campaign.get('date_end'), TimeFormat.isoDateTime);
  const canEditBudget = !!campaign.get('date_start') && !!campaign.get('date_end');
  const now = moment.utc();
  const [recBudget, setRecBudget] = React.useState<number>(0);
  const [minBudget, setMinBudget] = React.useState<number>(getMinBudget(campaign));
  const isDraft = campaign.get('state') === CampaignState.Draft;
  const isFacebook = [CampaignClass.Facebook, CampaignClass.EmailListFacebook].includes(
    campaign.get('_cls'),
  );
  const isComplete = dateEnd.isBefore(now);
  const isStarted = dateStart.isBefore(now);
  React.useEffect(() => {
    return autorun(() => {
      const audienceSize = getAudienceSize(campaign, segments, targetables);

      /*
       * Only set the recommended budget if the dates have been selected
       * since the recommendation is based on the duration of the campaign.
       *
       * Reset to 0 if the user clears a date.
       */
      const recommendedBudget = canEditBudget
        ? getRecommendedBudget(campaign, segments, targetables)
        : 0;
      const recommendedBid = getRecommendedBid(audienceSize);
      const minimumValidBudget = canEditBudget ? getMinBudget(campaign) : 0;

      if (!monetization) {
        const exposureSettings = campaign.get('exposure_settings');
        if (exposureSettings && !exposureSettings.custom_target && isDraft) {
          runInAction((): void => {
            set(exposureSettings, { target_value: minimumValidBudget });
          });
          campaign.setAttributeDirty('exposure_settings');
        }
        if (exposureSettings && !exposureSettings.custom_bid && isDraft) {
          runInAction((): void => {
            set(exposureSettings, {
              base_bid: recommendedBid,
              max_bid: recommendedBid * 2,
            });
          });
          campaign.setAttributeDirty('exposure_settings');
        }
      }
      setRecBudget(recommendedBudget);
      setMinBudget(minimumValidBudget);
    });
  }, [campaign, isDraft, monetization, segments, targetables, canEditBudget]);

  function handleTargetValueChange(newValue?: number): void {
    const exposureSettings = campaign.get('exposure_settings');
    if (exposureSettings) {
      runInAction((): void => {
        set(exposureSettings, {
          target_value: newValue ?? 0,
          /*
           * Once the user sets a custom target, we no longer modify the values in the
           * budget input based on date selection.
           */
          custom_target: true,
        });
      });
      campaign.setAttributeDirty('exposure_settings');
    }
  }

  function handleSetRecommendedBudget(): void {
    handleTargetValueChange(recommendationRaw);
  }

  function handleMonetizationValueChange(newValue?: number): void {
    campaign.set({ monetization_value: newValue ?? 0 });
  }

  const helpDeskLink = (): JSX.Element | null => {
    let prompt = '';
    let url = '';
    if (campaign.get('_cls') === CampaignClass.Segment) {
      prompt = 'how to set up Billing Configurations';
      url = 'https://help.feathr.co/hc/en-us/articles/360037323074-Feathr-Billing-Basics';
    } else {
      return null;
    }

    return (
      <p>
        <a href={url} target={'_blank'}>
          Check out our help desk to learn {prompt}.
        </a>
      </p>
    );
  };

  let description: ReactNode;
  if (isFacebook) {
    description = (
      <p>
        Here you will set the duration and budget for your campaign. Once you set the duration,
        Feathr will suggest a budget for you based on the duration, size of the audience and
        campaign type.
      </p>
    );
  } else if (!monetization) {
    description = (
      <>
        <p>
          Here you will set the duration and budget for your campaign. Once you set the duration,
          Feathr will suggest a budget for you based on the duration, size of the audience and
          campaign type.
        </p>
        <p>
          Feathr tries to spend enough each day to meet your campaign budget by the end of the
          campaign, but sometimes this is not possible due to limited audience availability.
        </p>
        {helpDeskLink()}
      </>
    );
  } else {
    description = (
      <>
        <p>Here you will set the duration and target impressions for your campaign.</p>
        <p>
          Feathr tries to bid enough each day to meet your campaign impression target by the end of
          the campaign, but sometimes this is not possible due to limited audience availability.
        </p>
        {helpDeskLink()}
      </>
    );
  }

  // Time stamps
  const startTimeStamp = campaign.get('date_start');
  const endTimeStamp = campaign.get('date_end');

  // Moment objects
  const startMoment = moment.utc(startTimeStamp).local();
  const endMoment = moment.utc(endTimeStamp).local();

  // ISO formatted timestamps
  const isoStartTimestamp =
    startTimeStamp && moment.utc(startTimeStamp).format(TimeFormat.isoDateTime);
  const isoEndTimestamp = endTimeStamp && moment.utc(endTimeStamp).format(TimeFormat.isoDateTime);

  function handleOnChangeSendStart(newTimestamp?: string): void {
    campaign.set({
      date_start: newTimestamp,
    });
  }

  function handleOnChangeDateEnd(newTimestamp?: string): void {
    campaign.set({
      date_end: newTimestamp,
    });
  }

  const overspendText = monetization
    ? t(
        'Due to standard bidding dynamics, campaigns may under or overreach their impression target by ~5%',
      )
    : t('Due to standard bidding dynamics, campaigns may overspend by ~5%');

  const target = getRecommendedImpressions(campaign, segments, targetables);
  const formattedTarget = numeral(target).format('0,0');
  const formattedBudget = numeral(recBudget).format('$0,0.00');
  const recommendationRaw = monetization ? target : recBudget ?? 0;
  const minBudgetFormatted = numeral(getMinBudget(campaign)).format('$0,0');

  /*
   * Only show the recommended target for monetization campaigns.
   * Do not show it if it's 0 (generally due to audience selection)
   */
  const targetRecommendation =
    monetization && target > 0
      ? t('Recommended target: {{target}}', { target: formattedTarget })
      : undefined;

  const alertMap = {
    [EAlertType.warning]: {
      title: t("The budget you've set will limit the performance of this campaign. "),
      description: t(
        'We highly recommend a budget of at least {{formattedBudget}} for the best results.',
        { formattedBudget },
      ),
      type: EAlertType.warning,
      name: 'alert_recommended_budget',
    },
    [EAlertType.danger]: {
      title: t('This campaign cannot be published with the set budget. '),
      description: t('The minimum budget required to publish this campaign is {{min}}.', {
        min: minBudgetFormatted,
      }),
      type: EAlertType.danger,
      name: 'alert_min_budget',
    },
  };

  const noDatesTarget = monetization ? t('impressions and sponsor package value') : t('budget');
  const targetValue = campaign.get('exposure_settings').target_value ?? 0;

  let alertProps: null | { title: string; description: string; type: EAlertType; name: string } =
    null;
  if (targetValue < minBudget) {
    alertProps = alertMap[EAlertType.danger];
  } else if (targetValue < recommendationRaw && !isFacebook) {
    alertProps = alertMap[EAlertType.warning];
  }

  function handleClearDate(type: 'end' | 'start'): () => void {
    return function () {
      campaign.set({ [`date_${type}`]: undefined });
    };
  }

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <NextStepButton
          account={account}
          billable={billable}
          campaign={campaign}
          event={event}
          key={'next'}
          onNext={onNext}
        />,
      ]}
      className={styles.root}
      description={description}
      label={'Edit Campaign: Budget & Duration'}
    >
      <Card width={'narrow'}>
        <Card.Header title={'Dates'} />
        <Card.Content addVerticalGap={true}>
          <Flex gap={cssVar('--spacing-4')} justify={'space-between'}>
            <DatePicker
              autoComplete={'off'}
              dateFormat={'MMM d, yyyy h:mm aa'}
              disabled={!isDraft && isStarted}
              isClearable={true}
              isFullWidth={true}
              label={t('Start date')}
              minDate={momentToDate(getMinStartDate(campaign.get('_cls')))}
              name={'date_start'}
              onClear={handleClearDate('start')}
              onDateStrChange={handleOnChangeSendStart}
              showTimeSelect={true}
              suffix={timezoneAbbr(startMoment.toDate())}
              timeIntervals={5}
              validationError={validationErrors.date_start}
              value={isoStartTimestamp}
            />
            <DatePicker
              autoComplete={'off'}
              dateFormat={'MMM d, yyyy h:mm aa'}
              disabled={!isDraft && isComplete}
              isClearable={true}
              isFullWidth={true}
              label={t('End date')}
              minDate={startMoment ? momentToDate(startMoment.add(getMinDuration())) : undefined}
              name={'date_end'}
              onClear={handleClearDate('end')}
              onDateStrChange={handleOnChangeDateEnd}
              showTimeSelect={true}
              suffix={timezoneAbbr(endMoment.toDate())}
              timeIntervals={5}
              validationError={validationErrors.date_end}
              value={isoEndTimestamp}
            />
          </Flex>
        </Card.Content>
      </Card>
      <Card width={'narrow'}>
        <Card.Header title={'Edit Budget'} />
        <Card.Content addVerticalGap={true}>
          <Flex align={'center'} gap={cssVar('--spacing-4')} justify={'space-between'}>
            {!canEditBudget && (
              <EmptyState
                description={t('Select a start and end date to access {{target}} selection.', {
                  target: noDatesTarget,
                })}
                label={'No dates selected'}
                name={'budget_empty_dates'}
                theme={'slate'}
                width={'full'}
              />
            )}
            {!monetization && canEditBudget && !isFacebook && (
              <RecommendedBudget
                budget={formattedBudget}
                isLoading={!recBudget}
                onClick={handleSetRecommendedBudget}
              />
            )}
            {canEditBudget && (
              <NumberInput
                additionalContent={targetRecommendation}
                className={classNames({ [styles.budgetInput]: !monetization })}
                data-name={monetization ? 'impressions_target_value' : 'budget_target_value'}
                disabled={!canEditBudget}
                helpPlacement={'top'}
                helpText={overspendText}
                label={monetization ? t('Impressions') : t('Budget')}
                min={monetization ? 10 : minBudget}
                name={'budget'}
                onChange={handleTargetValueChange}
                prefix={monetization ? null : '$'}
                validationError={validationErrors.budget}
                value={campaign.get('exposure_settings').target_value}
              />
            )}
          </Flex>

          {alertProps && !monetization && canEditBudget && (
            <Alert
              className={styles.marginBuster}
              description={alertProps.description}
              name={alertProps.name}
              title={alertProps.title}
              type={alertProps.type}
            />
          )}

          {monetization && canEditBudget && (
            <NumberInput
              data-name={'monetization_value'}
              helpText={t(
                'How much is this campaign worth to you? Usually this is how much your partner paid to you to run the campaign. This value is only used to provide reporting context.',
              )}
              label={t('Sponsor package value')}
              min={0}
              name={'monetization_value'}
              onChange={handleMonetizationValueChange}
              prefix={'$'}
              required={true}
              validationError={validationErrors.monetization_value}
              value={campaign.get('monetization_value')}
            />
          )}

          {!isFacebook && (
            <Collapse title={'Advanced Options'}>
              <p>
                These settings are preset for optimal performance based on your audience size and
                the campaign type. There are some cases where you may want to adjust them, but
                typically the preset values should be used.
              </p>
              <Fieldset direction={'column'}>
                <FreqCapSelect campaign={campaign} />
                <BaseBidSelect campaign={campaign} />
              </Fieldset>
              <FreqPeriodSelect campaign={campaign} />
            </Collapse>
          )}
        </Card.Content>
      </Card>

      {!isFacebook && <PaymentDetails billable={billable} campaign={campaign} event={event} />}
    </Form>
  );
}

export default observer(AdCampaignEditBudgetStep);
