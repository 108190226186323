import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { FocusEvent, JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ValueType } from 'react-select';

import type {
  Campaign,
  IExposureSettings,
  TCampaignAttributes,
  TFacebookCampaign,
} from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import { AlertV2, CreatableSelect, EAlertV2Type, Tooltip } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

import * as styles from './BaseBidSelect.css';

export interface IBaseBidSelectProps {
  campaign: Campaign<Exclude<TCampaignAttributes, TFacebookCampaign>>;
}

interface IOption {
  value: string;
  label: string;
}

function compareOption(inputValue: string, option: IOption): boolean {
  return inputValue === option.value;
}

function createOption(inputValue: string): IOption {
  const asNumeral = numeral(Number(inputValue));
  return { value: asNumeral.format('0.00'), label: asNumeral.format('$0,0.00') };
}

function BaseBidSelect({ campaign }: Readonly<IBaseBidSelectProps>): JSX.Element {
  const { t } = useTranslation();

  const bid = campaign.get('exposure_settings').base_bid;
  const isBidDirty = campaign.isAttributeDirty('exposure_settings');
  const initialBid = isBidDirty ? campaign.shadowAttributes['exposure_settings']?.base_bid : bid;
  const isLegacy: boolean =
    campaign.get('state') === CampaignState.Published && !!initialBid && initialBid < 5;

  const bidMultiplier = isLegacy ? 2 : 3;

  const onChangeBaseBid = (value: string | number): void => {
    if (campaign.get('_cls') === CampaignClass.Facebook) {
      return;
    }
    const newBid = Number(value);
    const exposureSettings: IExposureSettings = {
      ...campaign.get('exposure_settings'),
      base_bid: newBid,
      max_bid: newBid * bidMultiplier,
      custom_bid: true,
    };
    campaign.set({
      exposure_settings: exposureSettings,
    });
  };

  const defaultOptions: IOption[] = [{ value: '5', label: '$5.00' }];
  if (isLegacy) {
    defaultOptions.unshift({ value: '3', label: '$3.00' });
    defaultOptions.unshift({ value: '2', label: '$2.00' });
  } else {
    defaultOptions.push({ value: '7', label: '$7.00' });
  }
  defaultOptions.push({ value: '10', label: '$10.00' });

  function isValidValue(inputValue: string): boolean {
    const asNumber = Number(inputValue);
    const min = isLegacy ? 0.01 : 5;
    const max = isLegacy ? 15 : 99;
    return (
      // No empty strings
      !!inputValue &&
      // No non-numbers
      Number.isFinite(asNumber) &&
      // Between min and max
      asNumber >= min &&
      asNumber <= max
    );
  }

  function isValidNewOption(
    inputValue: string,
    _: ValueType<IOption>,
    options: readonly IOption[],
  ): boolean {
    return !(
      !isValidValue(inputValue) ||
      // No duplicates
      options.some((option) => compareOption(inputValue, option))
    );
  }

  function formatCreateLabel(inputValue: string): string {
    return t('Use custom value: {{value}}', {
      value: numeral(Number(inputValue)).format('$0,0.00'),
    });
  }

  function handleSelect(option: IOption): void {
    if (!isValidValue(option.value)) {
      return;
    }
    onChangeBaseBid(option.value);
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>): void {
    const value = e.currentTarget.value;
    if (!isValidValue(value)) {
      return;
    }
    onChangeBaseBid(value);
  }

  const label = (
    <>
      {t('Base bid')}{' '}
      <Tooltip
        title={t(
          'The starting bid Feathr will use before adjustments are applied. The number is expressed as a cost for a thousand impressions at the base bid.',
        )}
      >
        {getIconForAction('info')}
      </Tooltip>
    </>
  );

  return (
    <>
      <CreatableSelect<IOption>
        createOption={createOption}
        data-name={'base-bid'}
        defaultOptions={defaultOptions}
        formatCreateLabel={formatCreateLabel}
        isFullWidth={true}
        isValidNewOption={isValidNewOption}
        label={label}
        name={'base-bid'}
        onBlur={handleBlur}
        onSelectSingle={handleSelect}
        value={{
          value: String(bid),
          label: numeral(bid).format('$0,0.00'),
        }}
      />
      {
        // Base bids larger than $10 are not recommended
        !isLegacy && bid && bid > 10 && (
          <AlertV2
            className={styles.alert}
            description={t(
              'We recommend setting your base bid between $5 and $10. Adjust your base bid to get more impressions.',
            )}
            title={t('Your base bid is very high!')}
            type={EAlertV2Type.warning}
          />
        )
      }
    </>
  );
}

export default observer(BaseBidSelect);
