import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IPredicate, IUniquePredicate, TComparison } from '@feathr/blackbox';
import { useAccount, useStore } from '@feathr/extender/state';
import type { IBaseAttributes, Model } from '@feathr/rachis';

import ComparisonSelect from '../ComparisonSelect';
import RuleSelect from '../RuleSelect';
import type { IAttrOption, TOptionsGroup } from '../utils';
import { fieldToKind, getActivities, getAttributes, toPredicateAttrType } from '../utils';
import ValueInput from '../ValueInput';

import * as styles from './Predicate.css';
interface IProps {
  disabled?: boolean;
  handleChangeRule: (option: IAttrOption) => void;
  handleChangeComparison: (comparison: TComparison) => void;
  handleChangeValue: (value: any) => void;
  isGroup?: boolean;
  model: Model<IBaseAttributes>;
  optionsGroup?: TOptionsGroup;
  predicate: IUniquePredicate;
  triggers?: IPredicate[];
}

function Predicate({
  model,
  disabled = false,
  handleChangeRule,
  handleChangeComparison,
  handleChangeValue,
  optionsGroup = 'segment',
  predicate,
  triggers,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { isAnyFeathrPlatform } = useAccount();
  const { CustomFields } = useStore();

  const [attributes, setAttributes] = useState<IAttrOption[]>([]);
  const [activities, setActivities] = useState<IAttrOption[]>([]);
  const isLoading = useRef(true);
  const [rule, setRule] = useState<IAttrOption | undefined>();

  useEffect(() => {
    async function getAttributeOptions(): Promise<void> {
      const options = await getAttributes(t, CustomFields);
      setAttributes(options);
      isLoading.current = false;
    }

    async function getActivityOptions(): Promise<void> {
      const options = await getActivities({
        CustomFields,
        model,
        isAnyFeathrPlatform,
        optionsGroup,
        t,
      });
      setActivities(options);
      isLoading.current = false;
    }

    // We don't want to get attribute options for Meta and goal predicates.
    if (!['facebook', 'goal'].some((group) => optionsGroup.toLowerCase().includes(group))) {
      getAttributeOptions();
    }

    getActivityOptions();
  }, []);

  useEffect(() => {
    // Get the rule that matches the predicate's when the component loads.
    if (predicate.attr_against && !rule) {
      setRule(
        [...activities, ...attributes].find(({ id, type, kind }: IAttrOption) => {
          const attrMatch = predicate.attr_against === id;
          const typeMatch = predicate.attr_type === toPredicateAttrType(type);
          const kindMatch = predicate.kind === fieldToKind(kind);
          return attrMatch && typeMatch && kindMatch;
        }),
      );
    }
  }, [activities, attributes]);

  function handleSelectRule(option: IAttrOption): void {
    handleChangeRule(option);
    const newRule = [...activities, ...attributes].find(({ id, type, kind }: IAttrOption) => {
      const attrMatch = predicate.attr_against === id;
      const typeMatch = predicate.attr_type === toPredicateAttrType(type);
      const kindMatch = predicate.kind === fieldToKind(kind);
      return attrMatch && typeMatch && kindMatch;
    });
    setRule(newRule);
  }

  const props = {
    activities,
    attributes,
    disabled: disabled || model.get('read_only'),
    excludeIds: [],
    mode: model.get('mode'),
    optionsGroup,
    predicate,
    rule,
    handleSelectRule,
    handleChangeComparison,
    handleChangeValue,
    triggers,
  };

  const ruleSelect = <RuleSelect {...props} />;
  const comparison = <ComparisonSelect {...props} />;
  const value = <ValueInput {...props} />;
  return (
    <div className={styles.predicate}>
      {ruleSelect}
      {comparison}
      {value}
    </div>
  );
}

export default observer(Predicate);
