import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DripStepCampaign, TBaseUnitOfTime, TUnitOfTime } from '@feathr/blackbox';
import { ELinkedCampaignSetAction } from '@feathr/blackbox';
import { Fieldset, NumberInput, Select, Well } from '@feathr/components';
import { flattenError } from '@feathr/hooks';

import useDripText from '../../../useDripText';
import type { ICustomSelectOption } from '../TargetingOption';
import TargetingOption from '../TargetingOption';

import * as styles from './StepTargeting.css';

interface IStepTargetingProps {
  campaign: DripStepCampaign;
  validationErrors: any;
}

interface IMenuOption {
  id: TUnitOfTime;
  value: string;
}

function StepTargeting({ campaign, validationErrors }: Readonly<IStepTargetingProps>): JSX.Element {
  const { t } = useTranslation();
  const {
    builder: { targetingDelayHelpText, targetingDelayLabel },
  } = useDripText();
  const [targeting, setTargeting] = useState(
    campaign.get('set_action', ELinkedCampaignSetAction.previous_email_delivered),
  );
  const [delayUnit, setDelayUnit] = useState<TBaseUnitOfTime>(
    campaign.get('delay_unit') ?? 'minutes',
  );

  const targetingOptions: ICustomSelectOption[] = [
    {
      id: ELinkedCampaignSetAction.previous_email_delivered,
      name: t('Send to everyone'),
      description: t('This email will be sent to everybody that received the previous email.'),
    },
    {
      id: ELinkedCampaignSetAction.not_opened_previous_email,
      name: t('Not opened the previous email'),
      description: t(
        'This email will only be sent to people that received but did not open the previous email.',
      ),
    },
    {
      id: ELinkedCampaignSetAction.opened_previous_email,
      name: t('Opened the previous email'),
      description: t('This email will only be sent to people that have opened the previous email.'),
    },
    {
      id: ELinkedCampaignSetAction.not_clicked_link_previous_email,
      name: t('Not clicked the previous email'),
      description: t(
        'This email will only be sent to people that received but did not click a link in the previous email.',
      ),
    },
    {
      id: ELinkedCampaignSetAction.clicked_link_previous_email,
      name: t('Clicked the previous email'),
      description: t(
        'This email will only be sent to people that have clicked a link in the previous email.',
      ),
    },
  ];

  // TODO: should default to singular and pluralize based on the value
  const timeOptions: IMenuOption[] = [
    { id: 'minutes', value: 'minutes' },
    { id: 'hours', value: 'hours' },
    { id: 'days', value: 'days' },
    { id: 'weeks', value: 'weeks' },
  ];

  const minimumTimeValues = { minutes: 5, hours: 1, days: 1, weeks: 1 };

  function handleTargetingSelect(option: ICustomSelectOption): void {
    setTargeting(option.id);
    campaign.set({ set_action: option.id });
  }

  function getOptionLabelDelay(option: IMenuOption): string | undefined {
    const delayValue = campaign.get('delay_value');
    return {
      minutes: t('minute', { count: delayValue }),
      hours: t('hour', { count: delayValue }),
      days: t('day', { count: delayValue }),
      weeks: t('week', { count: delayValue }),
    }[option.id];
  }

  function getOptionValue(option: IMenuOption): string {
    return option.value;
  }

  function onSelectSingle(option: IMenuOption): void {
    setDelayUnit(option.id);
    campaign.set({ delay_unit: option.id, delay_value: minimumTimeValues[option.id] });
  }

  const selectedOption = targetingOptions.find((option) => option.id === targeting);

  return (
    <Well layout={'vertical'} theme={'white'}>
      <Fieldset>
        <Select
          additionalContent={!!selectedOption && <p>{selectedOption.description}</p>}
          components={{ Option: TargetingOption }}
          helpText={t('Select who should be sent the email in this step.')}
          label={t('Step targeting')}
          name={'step-targeting'}
          onSelectSingle={handleTargetingSelect}
          options={targetingOptions}
          placeholder={t('Select one')}
          value={selectedOption}
        />
        <NumberInput
          attribute={'delay_value'}
          className={styles.delay}
          clearableClassName={styles.delayElement}
          disabled={false}
          helpText={targetingDelayHelpText[targeting]}
          label={targetingDelayLabel[targeting]}
          min={minimumTimeValues[delayUnit]}
          model={campaign}
          name={'delay_value'}
          suffix={
            <Select
              disabled={false}
              getOptionLabel={getOptionLabelDelay}
              getOptionValue={getOptionValue}
              name={'delay_unit'}
              onSelectSingle={onSelectSingle}
              options={timeOptions}
              value={timeOptions.find((option) => option.id === delayUnit)}
            />
          }
          validationError={flattenError(validationErrors.delay_value)}
        />
      </Fieldset>
    </Well>
  );
}

export default observer(StepTargeting);
