import type { Active, DragOverEvent, Over, UniqueIdentifier } from '@dnd-kit/core';

import { FieldDataType } from '@feathr/blackbox';

// Fixture fields used for testing.
// TODO: Remove once person fields are integrated.
export const fields = [
  { id: 'name', type: FieldDataType.str, label: 'Name' },
  {
    id: 'level',
    type: FieldDataType.int,
    label: 'Level and level and level and level and level and level and level',
  },
  { id: 'mortal', type: FieldDataType.bool, label: 'Mortal' },
  { id: 'birthday', type: FieldDataType.date, label: 'Birthday' },
  { id: 'inventory', type: FieldDataType.list, label: 'Inventory' },
  { id: 'class', type: FieldDataType.str, label: 'Class and Rank and Decorations and Awards' },
  { id: 'max_hp', type: FieldDataType.int, label: 'Max HP' },
  { id: 'current_hp', type: FieldDataType.int, label: 'Current HP' },
  { id: 'alive', type: FieldDataType.bool, label: 'Alive' },
  { id: 'last_login', type: FieldDataType.date, label: 'Last Login' },
  { id: 'companions', type: FieldDataType.list, label: 'Companions' },
  { id: 'email', type: FieldDataType.str, label: 'Email address', value: 'sauron@mordor.com' },
  {
    id: 'ring_last_seen',
    type: FieldDataType.date,
    label: 'Ring Last Seen',
    value: new Date().toISOString(),
  },
];

export interface IField {
  id: string;
  help_text?: string;
  label: string;
  required?: boolean;
  type: FieldDataType;
  value?: string | number | boolean;
}

interface IBaseProps {
  availableFields: IField[];
  setAvailableFields: (fields: IField[]) => void;
  usedFields: IField[];
  setUsedFields: (fields: IField[]) => void;
}

export interface IDragOverEvent extends DragOverEvent {
  active: Active & {
    data: {
      current: {
        sortable: {
          containerId: 'form-builder' | 'configuration-panel';
        };
      };
    };
  };
  over: Over & {
    data: {
      current: {
        containerId: 'form-builder' | 'configuration-panel';
      };
    };
  };
}

// Props for the overall onDragOver handler
interface IHandlerProps extends IDragOverEvent, IBaseProps {}

// Props for container-specific drop handlers
interface IDropHandlerProps extends IBaseProps {
  activeId: UniqueIdentifier;
  overId: UniqueIdentifier;
  initialContainer: string;
}

function handleDropInBuilder({
  initialContainer,
  overId,
  activeId,
  availableFields,
  setAvailableFields,
  usedFields,
  setUsedFields,
}: IDropHandlerProps): void {
  const overIndex = usedFields.findIndex((field) => field.id === overId);

  // If the builder is empty, add the first field to the builder
  if (usedFields.length <= 0) {
    setUsedFields([availableFields.find((field) => field.id === activeId)!]);
    setAvailableFields(availableFields.filter((field) => field.id !== activeId));
    return;
  }

  // From configuration panel to builder
  if (initialContainer === 'configuration-panel') {
    const newBuilderFields = [
      ...usedFields,
      availableFields.find((field) => field.id === activeId)!,
    ];
    setUsedFields(newBuilderFields);
    setAvailableFields(availableFields.filter((field) => field.id !== activeId));
  }

  // From builder to builder
  if (initialContainer === 'form-builder') {
    const currentIndex = usedFields.findIndex((field) => field.id === activeId);
    const newBuilderFields = [...usedFields];

    // Remove the active field from its original position
    newBuilderFields.splice(currentIndex, 1);

    // Insert the active field at the new position
    newBuilderFields.splice(overIndex, 0, usedFields[currentIndex]);

    setUsedFields(newBuilderFields);
  }
}

function handleDropInConfigurationPanel({
  availableFields,
  setAvailableFields,
  usedFields,
  setUsedFields,
  initialContainer,
  overId,
  activeId,
}: IDropHandlerProps): void {
  const overIndex = availableFields.findIndex((field) => field.id === overId);

  // From builder to configuration panel
  if (initialContainer === 'form-builder') {
    const newAvailableFields = [
      ...availableFields.slice(0, overIndex),
      usedFields.find((field) => field.id === activeId)!,
      ...availableFields.slice(overIndex),
    ];
    setAvailableFields(newAvailableFields);
    setUsedFields(usedFields.filter((field) => field.id !== activeId));
  }

  // From configuration panel to configuration panel
  if (initialContainer === 'configuration-panel') {
    const currentIndex = availableFields.findIndex((field) => field.id === activeId);

    const newAvailableFields = [...availableFields];

    // Remove the active field from its original position
    newAvailableFields.splice(currentIndex, 1);

    // Insert the active field at the new position
    newAvailableFields.splice(overIndex, 0, availableFields[currentIndex]);

    setAvailableFields(newAvailableFields);
  }
}

export function onDragOver({
  availableFields,
  setAvailableFields,
  usedFields,
  setUsedFields,
  over,
  active: {
    id: activeId,
    data: {
      current: {
        sortable: { containerId: initialContainer },
      },
    },
  },
}: IHandlerProps): void {
  if (!over || !over?.id) {
    return;
  }

  const {
    id: overId,
    data: {
      current: {
        containerId: currentContainer = ['form-builder', 'configuration-panel'].includes(
          overId as string,
        )
          ? overId
          : undefined,
      } = { current: { containerId: overId } },
    },
  } = over;

  // Sortable items use an abstraction of droppable and draggable, so ignore on initial click
  if (overId === activeId) {
    return;
  }

  if (currentContainer === 'form-builder') {
    handleDropInBuilder({
      initialContainer,
      overId,
      activeId,
      availableFields,
      setAvailableFields,
      usedFields,
      setUsedFields,
    });
    return;
  }

  // Configuration panel
  if (currentContainer === 'configuration-panel') {
    handleDropInConfigurationPanel({
      initialContainer,
      overId,
      activeId,
      availableFields,
      setAvailableFields,
      usedFields,
      setUsedFields,
    });
    return;
  }
}
