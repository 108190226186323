import { useSortable } from '@dnd-kit/sortable';
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import type { JSX } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import type { FieldDataType } from '@feathr/blackbox';
import { Button, Icon } from '@feathr/components';
import { useTextwidth } from '@feathr/hooks';

import { typeToIcon } from './Field.utils';

import * as styles from './Field.css';

interface IField {
  id: string;
  type: FieldDataType;
  label: string;
}

interface IProps {
  isDragOverlay?: boolean;
  field: IField;
}

// Component for rendering fields in the configuration panel.
function Field({ field: { id, type, label }, isDragOverlay }: IProps): JSX.Element {
  const measureTextwidth = useTextwidth();
  const [showTooltip, setShowTooltip] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id,
    data: { containerId: 'configuration-panel' },
  });

  useEffect(() => {
    if (buttonRef.current) {
      const maxContentWidth = buttonRef.current.offsetWidth;
      const fontSize = 14;
      const textWidth = measureTextwidth(label, 'Inter', fontSize);
      setShowTooltip(isDragOverlay ? false : textWidth > maxContentWidth);
    }
  }, [buttonRef, label]);

  const element = showTooltip ? (
    <Button
      className={styles.container}
      prefix={<Icon className={styles.grip} icon={faGripDotsVertical} />}
      ref={buttonRef}
      tooltip={label}
    >
      <div className={styles.content}>
        <Icon className={styles.icon} icon={typeToIcon(type)} size={6} />
        <span className={styles.label}>{label}</span>
      </div>
    </Button>
  ) : (
    <Button
      className={styles.container}
      prefix={<Icon className={styles.grip} icon={faGripDotsVertical} />}
      ref={buttonRef}
    >
      <div className={styles.content}>
        <Icon className={styles.icon} icon={typeToIcon(type)} size={6} />
        <span className={styles.label}>{label}</span>
      </div>
    </Button>
  );
  return (
    <div
      className={classNames(styles.root, { [styles.placeholder]: isDragging })}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {element}
    </div>
  );
}

export default Field;
