import {
  faCheck,
  faClockRotateLeft,
  faCopy,
  faPencil,
  faPenToSquare,
  faShareNodes,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ContextMenu, Icon, Label, Tab } from '@feathr/components';
import { useActionBar } from '@feathr/extender/state';

import Page from '../../Page';
import { FormEditor } from './FormEditor';
import Settings from './Settings';

import * as styles from './FormEditPage.css';

function FormEditPage(): JSX.Element {
  const { t } = useTranslation();
  const { setLeftActions, setRightActions } = useActionBar();
  const [activeTab, setActiveTab] = useState<'edit' | 'settings'>('edit');

  useEffect(() => {
    setLeftActions(
      <span className={styles.leftActions}>
        <Label weight={'semibold'}>{t('Last saved:')} </Label>
        {/* TODO: This should reference `form.get('last_updated') */}
        <div className={styles.lastSaved}>{t('Thu Sep 21, 2024 3:45:34 PM EDT')}</div>
      </span>,
    );
    // TODO: Integrate these actions with the Form model.
    setRightActions([
      <Button key={'save-as-draft'} prefix={<Icon icon={faPenToSquare} />}>
        {t('Save as draft')}
      </Button>,
      <Button key={'publish'} prefix={<Icon icon={faCheck} />} type={'success'}>
        {t('Publish')}
      </Button>,
    ]);
  }, [setLeftActions, setRightActions, t]);

  function handleClickEditTab(): void {
    setActiveTab('edit');
  }

  function handleClickSettingsTab(): void {
    setActiveTab('settings');
  }

  const tabs = [
    <Tab
      active={activeTab === 'edit'}
      key={'edit'}
      onClick={handleClickEditTab}
      title={t('Edit')}
    />,
    <Tab
      active={activeTab === 'settings'}
      key={'settings'}
      onClick={handleClickSettingsTab}
      title={t('Settings')}
    />,
  ];

  // TODO: Integrate these actions with the Form model.
  const actions = [
    <ContextMenu iconName={'ellipsis'} key={'actions'}>
      <ContextMenu.Item key={'edit'} prefix={<Icon icon={faPencil} />}>
        {t('Edit')}
      </ContextMenu.Item>
      <ContextMenu.Item key={'version-history'} prefix={<Icon icon={faClockRotateLeft} />}>
        {t('Version history')}
      </ContextMenu.Item>
      <ContextMenu.Item key={'duplicate'} prefix={<Icon icon={faCopy} />}>
        {t('Duplicate')}
      </ContextMenu.Item>
      <ContextMenu.Item
        className={styles.deleteButton}
        key={'delete'}
        prefix={<Icon icon={faTrash} />}
      >
        {t('Delete')}
      </ContextMenu.Item>
    </ContextMenu>,
    <Button key={'share'} prefix={<Icon icon={faShareNodes} />} type={'secondary'}>
      {t('Share')}
    </Button>,
  ];

  // TODO: Page title should reference `form.get('name')`
  return (
    <Page
      actions={actions}
      legacyScrollClassName={styles.legacyScroll}
      tabs={tabs}
      title={t('Edit form')}
    >
      {activeTab === 'edit' && <FormEditor />}
      {activeTab === 'settings' && <Settings />}
    </Page>
  );
}

export default FormEditPage;
