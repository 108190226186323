import type { UniqueIdentifier } from '@dnd-kit/core';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { t } from 'i18next';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React from 'react';

import { Button, Icon, Input, Toggle } from '@feathr/components';

import type { IField } from '../../FormEditor.utils';

import * as styles from './FieldProperties.css';

interface IProps {
  field: IField;
  onFocusField: Dispatch<SetStateAction<IField | undefined>>;
  updateFieldProperties: (key: UniqueIdentifier, value: unknown) => void;
}

function FieldProperties({ field, onFocusField, updateFieldProperties }: IProps): JSX.Element {
  // TODO: This will likely change when we implement the Form model.
  function handleToggleRequired(): void {
    updateFieldProperties('required', !field.required);
  }

  function handleChangeLabel(newValue?: string): void {
    updateFieldProperties('label', newValue ?? '');
  }

  function handleChangeHelpText(newValue?: string): void {
    updateFieldProperties('help_text', newValue ?? '');
  }

  function handleChangePlaceholder(newValue?: string): void {
    updateFieldProperties('value', newValue ?? '');
  }

  function handleClickBack(): void {
    onFocusField(undefined);
  }

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Button
          onClick={handleClickBack}
          prefix={<Icon icon={faArrowLeft} />}
          type={'icon-outlined'}
        />
        <span className={styles.title}>{t('Edit {{field}}', { field: field.label })}</span>
      </div>
      <div className={styles.inputs}>
        <Toggle
          label={t('Make required')}
          layout={'well'}
          onChange={handleToggleRequired}
          value={field.required}
        />
        <Input
          label={t('Label')}
          onChange={handleChangeLabel}
          optional={true}
          value={field.label}
        />
        <Input
          label={t('Help text')}
          onChange={handleChangeHelpText}
          optional={true}
          value={field.help_text}
        />
        {/* TODO: This will have to render differently based on the input type */}
        <Input
          label={t('Placeholder text')}
          onChange={handleChangePlaceholder}
          optional={true}
          value={field.value as string}
        />
      </div>
    </div>
  );
}

export default FieldProperties;
