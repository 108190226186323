import type { IObservableArray } from 'mobx';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useParams } from 'react-router';

import type { Goal, LandingPageCampaign, Segment, Template } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Spinner, Tab, Toolbar } from '@feathr/components';
import CampaignPage from '@feathr/extender/App/EventsPage/CampaignPage';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import type { IValidation, Model } from '@feathr/rachis';

import SaveCampaignButton from '../../CampaignEditPage/SaveCampaignButton';
import { getGoalSegments, validateStepGoals } from '../../CampaignEditPage/StepGoals';
import type { ICampaignValidationErrors } from '../../CampaignSummary/CampaignSummary';

interface IOuterProps {
  children: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
  wrapActions?: (children: ReactNode) => JSX.Element;
}

function validate(
  campaign: LandingPageCampaign,
  template: Template | undefined,
  goals: IObservableArray<Goal>,
  goalSegments: Segment[],
): IValidation<ICampaignValidationErrors> {
  const goalsErrors = validateStepGoals(goals, goalSegments);
  const templateErrors = template?.validate(['short_code'], false, 'flat');
  const campaignErrors = campaign.validate(
    ['_cls', ...Object.keys(campaign.constraints)],
    false,
    'grouped',
  );

  return observable({
    attributes: ['name', 'date_start', 'template', 'goals'],
    errors: observable({
      name: campaignErrors.errors.name as string[] | undefined,
      date_start: campaignErrors.errors.date_start as string[] | undefined,
      // templateErrors.errors is an observable array
      template: templateErrors?.errors,
      goals: goalsErrors.goals,
    }),
    isPending: !templateErrors || templateErrors.isPending || campaignErrors.isPending,
  });
}

function LandingPageCampaignPage({
  children,
  description,
  title,
  wrapActions,
}: Readonly<IOuterProps>): JSX.Element {
  const localUrl = useLocalUrl();
  const { campaignId } = useParams<{ campaignId: string }>();
  const { Campaigns, Templates, Goals, Segments } = useStore();
  const campaign = Campaigns.get(campaignId) as LandingPageCampaign;

  const templateId = !campaign.isPending ? campaign.get('template').id : undefined;
  const template = templateId ? Templates.get(templateId) : undefined;
  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const goalSegments = getGoalSegments(goals.models, Segments);

  const campaignState = campaign.get('state');
  const childModels: Model[] = template ? [...goals.models, template] : goals.models;
  const grandchildModels: Model[] = [...goalSegments];

  function buttonValidate(): IValidation<ICampaignValidationErrors> {
    return validate(campaign, template, goals.models, goalSegments);
  }

  const validatedSaveButton =
    campaignState === CampaignState.Published ? (
      <SaveCampaignButton
        campaign={campaign}
        childModels={childModels}
        grandchildModels={grandchildModels}
        key={'validatedSave'}
        name={'save_changes'}
        showIcon={true}
        tooltipPosition={'top-end'}
        type={'primary'}
        validate={buttonValidate}
      />
    ) : (
      <SaveCampaignButton
        campaign={campaign}
        childModels={childModels}
        grandchildModels={grandchildModels}
        key={'publish'}
        name={'publish_or_stop'}
        shouldChangeState={true}
        tooltipPosition={'top-end'}
        validate={buttonValidate}
      />
    );

  const button =
    campaign.get('state') === CampaignState.Published
      ? [validatedSaveButton]
      : [
          <SaveCampaignButton
            campaign={campaign}
            childModels={
              template
                ? [...goalSegments, ...goals.models, template]
                : [...goalSegments, ...goals.models]
            }
            grandchildModels={grandchildModels}
            key={'save'}
            showIcon={true}
            type={'primary'}
          />,
          validatedSaveButton,
        ];

  if (campaign.isPending) {
    return <Spinner />;
  }
  const tabs = [
    <Tab exact={true} key={'report'} link={localUrl(campaign.getItemUrl())} title={'Report'} />,
    <Tab key={'page'} link={localUrl(campaign.getItemUrl('template'))} title={'Page'} />,
    <Tab key={'settings'} link={localUrl(campaign.getItemUrl('settings'))} title={'Settings'} />,
  ];

  const actions = wrapActions ? wrapActions(button) : <Toolbar>{button}</Toolbar>;

  return (
    <CampaignPage actions={actions} campaign={campaign} tabs={tabs} title={campaign.name}>
      <h3>{title}</h3>
      {!!description && <div className={''}>{description}</div>}
      {children}
    </CampaignPage>
  );
}

export default observer(LandingPageCampaignPage);
