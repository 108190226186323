import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Account, IEmailPreferences } from '@feathr/blackbox/src/accounts';
import {
  Button,
  CardV2 as Card,
  ImagePicker,
  Input,
  Label,
  SaveButton,
  Time,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount, useActionBar, useUser } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import { pickerConfig } from './subscriptionsPage.utils';

import * as styles from './SubscriptionsPage.css';

export type TSubscriptionPicker = Pick<IEmailPreferences, 'logo_url' | 'favicon_url'>;
export type TSubscriptionPickerKey = keyof TSubscriptionPicker;

function SubscriptionsPage(): JSX.Element {
  const { t } = useTranslation();
  const { setLeftActions, setRightActions } = useActionBar();

  const account = useAccount();
  const user = useUser();

  const emailPreferences = account.get('email_preferences');
  const userTimeZone = user.get('timezone');

  useEffect(() => {
    setLeftActions(
      <>
        <Label margin={'condensed'} weight={'semibold'}>
          {t('Last saved: ')}
        </Label>
        <Time
          format={TimeFormat.shortWeekdayDateTime}
          timestamp={emailPreferences?.date_last_modified ?? t('Never')}
          timezone={userTimeZone}
        />
      </>,
    );
    setRightActions(
      <SaveButton disabled={!account.isDirty} method={'patch'} model={account}>
        {t('Save')}
      </SaveButton>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.isDirty]);

  function handleOnRemove(key: TSubscriptionPickerKey): void {
    account.set({
      email_preferences: { ...emailPreferences, [key]: '' },
    });
  }

  function handleOnRemoveLogo(): void {
    handleOnRemove('logo_url');
  }

  function handleOnRemoveFavicon(): void {
    handleOnRemove('favicon_url');
  }

  return (
    <Page
      description={t(
        'Preview the language that email recipients will see on their subscription preferences management page.',
      )}
      title={'Subscriptions'}
    >
      <Card name={'settings-card'}>
        <Card.Header title={t('Settings')} />
        <Card.Content
          addVerticalGap={true}
          description={t(
            "Set a unique name for your account’s subscription. This name will be visible to email recipients when they manage their subscription preferences. If you don't set a name, it will default to your account's name.",
          )}
          title={t('Custom subscription name')}
        >
          <Input
            attribute={['email_preferences', 'alias']}
            isClearable={true}
            model={account}
            placeholder={account.name}
          />
        </Card.Content>
        <Card.Content
          addVerticalGap={true}
          description={t(
            'Add a custom logo to personalize your subscription management page. Accepts JPG, PNG or SVG. 2 MB max. Minimum height and minimum width: 50px',
          )}
          title={t('Subscription page header image')}
        >
          <ImagePicker<Account>
            attribute={['email_preferences', 'logo_url']}
            buttonText={emailPreferences?.logo_url ? t('Replace logo') : t('Upload logo')}
            className={styles.imagePicker}
            hideImagePlaceholder={true}
            imgClassName={styles.logoUrl}
            model={account}
            pickerOptions={pickerConfig?.logo_url}
          >
            {emailPreferences?.logo_url && (
              <Button onClick={handleOnRemoveLogo}>{t('Remove')}</Button>
            )}
            {emailPreferences?.logo_url && (
              <img alt={t('Logo')} className={styles.logoUrl} src={emailPreferences?.logo_url} />
            )}
          </ImagePicker>
        </Card.Content>
        <Card.Content
          addVerticalGap={true}
          description={t(
            'Add a custom favicon to your subscription management page. Accepts JPG, PNG, or SVG. 2 MB max. Maximum height and maximum width: 32px.',
          )}
          title={t('Subscription page favicon')}
        >
          <ImagePicker<Account>
            attribute={['email_preferences', 'favicon_url']}
            buttonText={emailPreferences?.favicon_url ? t('Replace favicon') : t('Upload favicon')}
            className={styles.imagePicker}
            hideImagePlaceholder={true}
            imgClassName={styles.faviconUrl}
            model={account}
            pickerOptions={pickerConfig.favicon_url}
          >
            {emailPreferences?.favicon_url && (
              <Button onClick={handleOnRemoveFavicon}>{t('Remove')}</Button>
            )}
            {emailPreferences?.favicon_url && (
              <img
                alt={t('Favicon')}
                className={styles.faviconUrl}
                src={emailPreferences?.favicon_url}
              />
            )}
          </ImagePicker>
        </Card.Content>
      </Card>

      <div className={styles.labelWrapper}>
        <Label weight={'semibold'}>{t('Preview')}</Label>
      </div>

      {/* TODO: The email preview will be redesigned in a future release. */}
      <Card name={'preview-card'}>
        <Card.Content padding={'none'}>
          <iframe
            className={styles.preview}
            key={emailPreferences.date_last_modified}
            src={account.emailPreferencesPreviewUrl}
            title={'Preview'}
          />
        </Card.Content>
      </Card>
    </Page>
  );
}

export default observer(SubscriptionsPage);
