import { useDroppable } from '@dnd-kit/core';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { FieldDataType } from '@feathr/blackbox';
import { Button } from '@feathr/components';

import type { IField } from '../FormEditor.utils';
import DraggableFormElement from './DraggableFormElement';

import * as styles from './Builder.css';

interface IProps {
  fields: IField[];
  onFocusField: Dispatch<SetStateAction<IField | undefined>>;
}

interface IRowItem {
  type: FieldDataType;
  label: string;
  id: string;
  placeholder?: string;
  required: boolean;
}

// TODO: This is a proto-interface for the config object that will be stored on the form.
interface IConfig {
  settings: {
    submit: { label: string; color: string };
  };
  rows: IRowItem[][];
}

function Builder({ fields, onFocusField }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { setNodeRef: setDroppableRef } = useDroppable({
    id: 'form-builder',
  });
  // TODO: Once Form model is created, this will be initialized with `form.get('config')`
  const [config, setConfig] = useState<IConfig>({
    settings: { submit: { label: 'Submit', color: 'blue' } },
    rows: [],
  });

  // This is where we will set the updated config on the Form model.
  useEffect(() => {
    if (fields.length === 0) {
      return;
    }

    // TODO: Placeholder and require will be set in a different tab, which is not completed yet.
    const fieldsToRows = fields.map((field) => [
      {
        type: field.type,
        label: field.label,
        id: field.id,
        placeholder: '',
        required: false,
      },
    ]);

    const newConfig = { ...config, rows: [...fieldsToRows] } as IConfig;

    // This will become `form.set({ config: newConfig })`
    setConfig(newConfig);
  }, [fields]);

  return (
    <div className={styles.root} data-name={'form-builder'} ref={setDroppableRef}>
      <div className={styles.content}>
        {fields.map((field) => (
          <DraggableFormElement field={field} key={field.id} onFocusField={onFocusField} />
        ))}
        {/* TODO: This button will be customizable */}
        <Button className={styles.submitButton}>{t('Submit')}</Button>
      </div>
    </div>
  );
}

// TODO: Once Form model is created, this will need to be wrapped in observer.
export default Builder;
