import type { WithT } from 'i18next';
import debounce from 'lodash.debounce';
import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type {
  Account,
  Campaign,
  Domain,
  EmailVerification,
  EmailVerifications as EmailVerificationsCollection,
  Goal,
  Segment,
  SmartPinpointEmailCampaign,
  TFlagsRecord,
} from '@feathr/blackbox';
import { CampaignState, PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { Step, Steps, Wizard } from '@feathr/components';
import { useAccount, useFlags, useStore } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT, flattenErrors, useReactionEffect } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import type {
  IActionErrors,
  ICampaignValidationErrors,
} from '../../CampaignSummary/CampaignSummary';
import { getIsVerifiedDomainSender } from '../PinpointEmailCampaignEdit/PinpointEmailCampaignEdit';
import PinpointEmailCampaignStepFour, {
  validateStepFour,
} from '../PinpointEmailCampaignEdit/PinpointEmailCampaignStepFour';
import PinpointEmailCampaignStepSeven from '../PinpointEmailCampaignEdit/PinpointEmailCampaignStepSeven';
import PinpointEmailCampaignStepTest from '../PinpointEmailCampaignEdit/PinpointEmailCampaignStepTest';
import PinpointEmailCampaignStepTwo, {
  validateStepTwo,
} from '../PinpointEmailCampaignEdit/PinpointEmailCampaignStepTwo';
import SaveCampaignButton from '../SaveCampaignButton';
import StepGoals, { getGoalSegments, validateStepGoals } from '../StepGoals';
import SmartPinpointEmailCampaignStep, {
  validateSmartStep,
} from './SmartPinpointEmailCampaignStep';
import SmartPinpointEmailCampaignStepOne, {
  validateStepOne,
} from './SmartPinpointEmailCampaignStepOne';
import SmartPinpointEmailCampaignStepThree, {
  validateStepThree,
} from './SmartPinpointEmailCampaignStepThree';

interface IProps {
  campaign: SmartPinpointEmailCampaign;
}

interface IValidationDetails extends WithT {
  account: Account;
  campaign: SmartPinpointEmailCampaign;
  emailVerification?: EmailVerification;
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  domains?: IObservableArray<Domain>;
  segments?: Segment[];
  flags: Partial<TFlagsRecord>;
}

const getEmailVerifications = debounce(
  (campaign: SmartPinpointEmailCampaign, EmailVerifications: EmailVerificationsCollection) => {
    return campaign.getEmailVerifications(EmailVerifications);
  },
  DEFAULT_DEBOUNCE_WAIT,
  { leading: true },
);

function validate({
  account,
  campaign,
  emailVerification,
  goals,
  goalSegments,
  segments,
  domains,
  t,
  flags,
}: IValidationDetails): ICampaignValidationErrors {
  const one = validateStepOne(campaign);
  const two = validateStepTwo(campaign, emailVerification);
  const three = validateStepThree(campaign);
  const four = validateSmartStep(campaign);
  const five = validateStepFour(campaign);
  const six = validateStepGoals(goals, goalSegments);

  const validationErrors: ICampaignValidationErrors = {
    name: (one.name as string[]) || [],
    date_start: (one.date_start as string[]) || [],
    from_address: (two.from_address as string[]) || [],
    segments: three.segments as string[],
    target_segment_id: (four.target_segment_id as string[]) || [],
    actions: (four.actions as IActionErrors[]) || [],
    delay_value: (four.delay_value as string[]) || [],
    delay_unit: (four.delay_unit as string[]) || [],
    date_send_end: (four.date_send_end as string[]) || [],
    subject: (five.subject as string[]) || [],
    template: (five.template_id as string[]) || [],
    plain_text_body: (five.plain_text_body as string[]) || [],
    goals: six.goals,
  };
  if (account.get('email_health') === 'suspended') {
    validationErrors.account = [
      t('Your Feathr account is suspended and cannot publish any new email campaigns.'),
    ];
  }

  if (
    emailVerification &&
    segments &&
    !getIsVerifiedDomainSender(domains!, emailVerification, flags?.bypassMX)
  ) {
    const targetedPeople = segments.reduce((total, segment) => {
      return total + (segment.get('stats').num_emails_total || 0);
    }, 0);

    if (targetedPeople > 1000) {
      validationErrors.contacts_limit = [
        t('You cannot target more than 1,000 contacts unless you authorize your sender domain.'),
      ];
    }
  }

  return validationErrors;
}

const getCompletedStep = ({
  campaign,
  emailVerification,
  goals,
  goalSegments,
}: IValidationDetails): number => {
  if (flattenErrors(validateStepOne(campaign)).length) {
    return 0;
  }

  if (flattenErrors(validateStepTwo(campaign, emailVerification)).length) {
    return 1;
  }

  if (flattenErrors(validateStepFour(campaign)).length) {
    return 2;
  }

  if (flattenErrors(validateStepThree(campaign)).length) {
    return 3;
  }

  if (flattenErrors(validateSmartStep(campaign)).length) {
    return 4;
  }

  if (flattenErrors(validateStepGoals(goals, goalSegments)).length) {
    return 5;
  }

  // We don't validate the test step because a test send is not necessary to be able to publish a campaign.

  return 7;
};

function getHashStep(): number | undefined {
  const matchedHash = /\#step(\d{1})$/.exec(location.hash);
  return matchedHash ? +matchedHash[1] - 1 : undefined;
}

function SmartPinpointEmailCampaignEdit({ campaign }: IProps): JSX.Element {
  const account = useAccount();
  const flags = useFlags();
  const { Domains, EmailVerifications, Goals, Segments, Templates } = useStore();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [completeStep, setCompleteStep] = useState<number>(0);
  const [testEmail, setTestEmail] = useState<string | undefined>();
  const [testPersonId, setTestPersonId] = useState<string | undefined>();
  const { t } = useTranslation();
  const hashStep = getHashStep();

  const domains = Domains.list();

  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const segments = campaign
    .get('segments', [])
    .filter(({ id }) => !!id)
    .map(({ id }) => Segments.get(id));
  const templates = campaign.get('template_id') ? [Templates.get(campaign.get('template_id'))] : [];
  // Used for validation only.
  const emailVerifications = getEmailVerifications(campaign, EmailVerifications);

  // Converting observables back to vanilla JavaScript.
  const childModels: Model[] = [...templates, ...segments, ...goals.models.slice()];
  const grandchildModels: Model[] = [...getGoalSegments(goals.models, Segments)];

  const doCompleteStep = (): void => {
    if (
      !emailVerifications ||
      campaign.isPending ||
      emailVerifications.isPending ||
      goals.isPending
    ) {
      return;
    }

    const goalSegments = getGoalSegments(goals.models, Segments);
    const emailVerification = campaign.getEmailVerification(emailVerifications);

    if (
      [CampaignState.Published, CampaignState.Publishing, CampaignState.Stopped].includes(
        campaign.get('state', CampaignState.Draft),
      )
    ) {
      if (hashStep) {
        setCurrentStep(hashStep);
      } else {
        setCurrentStep(7);
      }
      setCompleteStep(7);
    } else {
      const step = getCompletedStep({
        account,
        campaign,
        emailVerification,
        goals: goals.models,
        goalSegments,
        t,
        flags,
      });
      if (hashStep) {
        setCurrentStep(hashStep);
      } else {
        setCurrentStep(step);
      }
      setCompleteStep(step);
    }
  };

  function buttonValidate(): ICampaignValidationErrors {
    if (
      !account ||
      !emailVerifications ||
      campaign.isPending ||
      emailVerifications.isPending ||
      goals.isPending ||
      domains.isPending
    ) {
      return {};
    }

    const emailVerification = campaign.getEmailVerification(emailVerifications);
    const goalSegments = getGoalSegments(goals.models, Segments);
    return validate({
      account,
      campaign,
      emailVerification,
      goals: goals.models,
      goalSegments,
      domains: domains.models,
      segments,
      t,
      flags,
    });
  }

  useReactionEffect(
    () =>
      !!emailVerifications &&
      !campaign.isPending &&
      !emailVerifications.isPending &&
      !goals.isPending,
    () => {
      if (
        !emailVerifications ||
        campaign.isPending ||
        emailVerifications.isPending ||
        goals.isPending
      ) {
        return;
      }
      doCompleteStep();
    },
    { once: true },
  );

  function onNext(): void {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    history.replace({ ...history.location, hash: `step${nextStep + 1}` });
    if (nextStep > completeStep) {
      setCompleteStep(nextStep);
    }
  }

  function onPrev(): void {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
    history.replace({ ...history.location, hash: `step${prevStep + 1}` });
  }

  function handleChangeStep(step: number): void {
    setCurrentStep(step);
    history.replace({ ...history.location, hash: `step${step + 1}` });
  }

  async function preSave(newCampaign: Campaign): Promise<void> {
    if (newCampaign instanceof PinpointEmailBaseCampaign) {
      const campaignSegments = newCampaign.get('segments');
      await Promise.all(
        campaignSegments.map(async (s) => {
          if (!s.id) {
            return;
          }
          const campaignSegment = Segments.get(s.id);
          await when(() => !campaignSegment.isPending);
          if (campaignSegment.isEphemeral) {
            await Segments.add(campaignSegment);
          }
        }),
      );
    }
  }

  const isDisabledChangeState = ((): boolean => {
    const state = campaign.get('state', CampaignState.Draft);
    const isDraft = state === CampaignState.Draft;
    const isStopable = state === CampaignState.Published;
    if (isDraft) {
      return completeStep < 5 || !campaign.editable;
    }
    return !isStopable;
  })();

  const saveButton = (
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      disabled={isDisabledChangeState}
      grandchildModels={grandchildModels}
      key={'changeState'}
      name={'publish_or_stop'}
      preSave={preSave}
      shouldChangeState={true}
      validate={buttonValidate}
    />
  );

  const actions = [
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      disabled={
        campaign.get('state', CampaignState.Draft) === CampaignState.Publishing ||
        !campaign.editable ||
        undefined
      }
      grandchildModels={grandchildModels}
      key={'save'}
      name={'save_changes'}
      preSave={preSave}
    />,
    saveButton,
  ];

  const steps = [
    <Step key={1} stepIndex={0} title={t('Name')} />,
    <Step key={2} stepIndex={1} title={t('Sender Info')} />,
    <Step key={3} stepIndex={2} title={t('Message')} />,
    <Step key={4} stepIndex={3} title={t('Audience')} />,
    <Step key={5} stepIndex={4} title={t('Smart Settings')} />,
    <Step key={6} stepIndex={5} title={t('Goals (optional)')} />,
    <Step key={7} stepIndex={6} title={t('Test')} />,
    <Step key={8} stepIndex={7} title={t('Review')} />,
  ];

  const wizardProps = {
    campaign,
    onNext,
    onPrev,
    goals: goals.models,
    validate,
    disabled: campaign.readOnly,
    isVerifiedDomainSender: getIsVerifiedDomainSender(
      domains.models,
      campaign.getEmailVerification(emailVerifications),
      flags.bypassMX,
    ),
    validationErrors: buttonValidate(),
  };

  return (
    <div data-appcues-campaign={campaign.get('_cls')}>
      <Wizard
        actions={actions}
        isLoading={campaign.isPending || goals.isPending}
        steps={
          <Steps completed={completeStep} current={currentStep} onChange={handleChangeStep}>
            {steps}
          </Steps>
        }
      >
        {currentStep === 0 && <SmartPinpointEmailCampaignStepOne {...wizardProps} />}
        {currentStep === 1 && <PinpointEmailCampaignStepTwo {...wizardProps} />}
        {currentStep === 2 && <PinpointEmailCampaignStepFour {...wizardProps} />}
        {currentStep === 3 && <SmartPinpointEmailCampaignStepThree {...wizardProps} />}
        {currentStep === 4 && <SmartPinpointEmailCampaignStep {...wizardProps} />}
        {/* Goals step should always be enabled - so override disabled set by campaign.readOnly */}
        {currentStep === 5 && <StepGoals {...wizardProps} disabled={false} />}
        {/* Test step should always be enabled - so override disabled set by campaign.readOnly */}
        {currentStep === 6 && (
          <PinpointEmailCampaignStepTest
            {...wizardProps}
            disabled={false}
            setTestEmail={setTestEmail}
            setTestPersonId={setTestPersonId}
            testEmail={testEmail}
            testPersonId={testPersonId}
          />
        )}
        {currentStep === 7 && (
          <PinpointEmailCampaignStepSeven
            {...wizardProps}
            saveButton={saveButton}
            validationErrors={buttonValidate()}
          />
        )}
      </Wizard>
    </div>
  );
}

export default observer(SmartPinpointEmailCampaignEdit);
