import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import type { RowRenderProps } from 'react-table';

import type { Breadcrumb, Conversion, IPredicateGroup } from '@feathr/blackbox';
import { Button, Popover, Toolbar } from '@feathr/components';
import { cssVar, moment, TimeFormat } from '@feathr/hooks';
import { useStore } from '@feathr/report_components/state';

import type { TFlavor } from '../FlavorChip';
import FlavorChip, { flavorToColorMap } from '../FlavorChip';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IRow extends RowRenderProps {
  original: Conversion;
}

interface IProps {
  conversion: Conversion;
}

function getCrumbPosition(crumb: Breadcrumb, start: number, end: number, width: number): number {
  const ts = moment.utc(crumb.get('d_c'), moment.ISO_8601).unix();
  const fullDelta = end - start;
  const crumbDelta = ts - start;
  const pos = (crumbDelta / fullDelta) * width;
  return pos;
}

function getCrumbColor(crumb: Breadcrumb, conversion: Conversion): string | undefined {
  if (crumb.id === conversion.id) {
    return flavorToColorMap.conversion;
  }
  return flavorToColorMap[crumb.get('flvr') as TFlavor];
}

const ConversionTimelineSubComponent = observer((props: Readonly<IProps>): JSX.Element => {
  const { conversion } = props;
  const start = conversion.get('date_enter_funnel');
  const end = conversion.get('d_c');
  const minStart = moment.utc(start, moment.ISO_8601).unix();
  const maxEnd = moment.utc(end, moment.ISO_8601).unix();
  const maxDelta = maxEnd - minStart;
  const [currentStart, setCurrentStart] = useState(minStart);
  const [currentEnd, setCurrentEnd] = useState(maxEnd);
  const currentDelta = currentEnd - currentStart;
  const [x, setX] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const getOnWheel = () => {
    return (wheelEvent: WheelEvent) => {
      wheelEvent.preventDefault();
      const wheelDir = wheelEvent.deltaY >= 0 ? 1 : -1;
      const normalizedX = x / 800;
      const xTimestamp = currentStart + currentDelta * normalizedX;
      const startScaleFactor = normalizedX;
      const endScaleFactor = 1.0 - normalizedX;
      const zoomSpeed = 0.3;
      let nextStart =
        currentStart + (xTimestamp - currentStart) * startScaleFactor * wheelDir * zoomSpeed;
      nextStart = Math.min(nextStart, xTimestamp - 60);
      nextStart = Math.max(nextStart, minStart);
      let nextEnd = currentEnd + (xTimestamp - currentEnd) * endScaleFactor * wheelDir * zoomSpeed;
      nextEnd = Math.max(nextEnd, xTimestamp + 60);
      nextEnd = Math.min(nextEnd, maxEnd);
      setCurrentStart(nextStart);
      setCurrentEnd(nextEnd);
    };
  };
  useEffect(() => {
    const containerDiv = containerRef.current;
    const onWheel = getOnWheel();
    if (containerDiv) {
      containerDiv.addEventListener('wheel', onWheel, { passive: false });
    }
    return () => {
      if (containerDiv) {
        containerDiv.removeEventListener('wheel', onWheel);
      }
    };
  });
  const predicates: IPredicateGroup[] = [
    {
      kind: 'activity',
      mode: 'match_all',
      group: [
        {
          kind: 'activity',
          comparison: 'eq',
          attr_against: 'f_id',
          attr_type: 'string',
          value: conversion.get('cookie'),
        },
        {
          kind: 'activity',
          comparison: 'gte',
          attr_against: 'd_c',
          attr_type: 'date',
          value: start,
        },
        {
          kind: 'activity',
          comparison: 'lte',
          attr_against: 'd_c',
          attr_type: 'date',
          value: end,
        },
      ],
    },
  ];

  const { Breadcrumbs } = useStore();
  const breadcrumbs = Breadcrumbs.list({
    predicates,
    mode: 'match_all',
    lookback_mode: 'unbounded',
    ordering: ['d_c'],
    pagination: {
      page: 0,
      page_size: 1000,
    },
  });
  if (breadcrumbs.isPending) {
    return (
      <div
        style={{
          width: '100%',
          height: '75px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className={skeleton} style={{ width: '1100px', height: '75px' }} />
      </div>
    );
  }

  function handleMouseMove(mouseEvent: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    const containerDiv = containerRef.current;
    if (containerDiv) {
      const containerRect = containerDiv.getBoundingClientRect();
      const mouseX = mouseEvent.pageX - containerRect.left;
      if (mouseX >= 0 && mouseX !== x) {
        setX(mouseX);
      }
    }
  }

  function handleReset(): void {
    setCurrentStart(minStart);
    setCurrentEnd(maxEnd);
  }

  return (
    <div
      style={{
        padding: '20px 20px 20px 50px',
        width: '100%',
        height: '75px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        boxShadow: 'inset 0px 0px 8px 2px rgba(50, 50, 100, .2)',
      }}
    >
      <div
        onMouseMove={handleMouseMove}
        ref={containerRef}
        style={{ width: '800px', position: 'relative', marginRight: '45px' }}
      >
        {breadcrumbs.models
          .filter((crumb) => {
            const crumbTimestamp = moment.utc(crumb.get('d_c'), moment.ISO_8601).unix();
            return crumbTimestamp >= currentStart && crumbTimestamp <= currentEnd;
          })
          .map((crumb) => {
            const crumbPosition = getCrumbPosition(crumb, currentStart, currentEnd, 800);
            return (
              <div
                key={crumb.id}
                style={{
                  position: 'absolute',
                  top: '-4px',
                  left: `${crumbPosition}px`,
                  transition: 'left .3s',
                }}
              >
                <Popover position={'bottom'} toggle={'onMouseOver'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: `2px solid ${cssVar('--color-text-body-inverted')}`,
                      boxShadow: cssVar('--drop-shadow'),
                      borderRadius: '50%',
                      backgroundColor:
                        getCrumbColor(crumb, conversion) || cssVar('--color-primary-1'),
                      zIndex: 3,
                    }}
                  />
                  <div>
                    <FlavorChip flavor={crumb.get('flvr') as TFlavor} />
                    {crumb.get('loc_url') && <div>{new URL(crumb.get('loc_url')).host}</div>}
                    <div>
                      {moment
                        .utc(crumb.get('d_c'), moment.ISO_8601)
                        .format(TimeFormat.shortDateTime)}
                    </div>
                  </div>
                </Popover>
              </div>
            );
          })}
        <div
          style={{
            zIndex: 1,
            height: '4px',
            width: '800px',
            borderRadius: '4px',
            backgroundColor: cssVar('--color-text-body'),
          }}
        />
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <div
            style={{ fontSize: '12px', color: cssVar('--color-text-body'), marginLeft: '-30px' }}
          >
            {moment.unix(currentStart).utc().format('MMM Do hh:mm a')}
          </div>
          <div
            style={{ fontSize: '12px', color: cssVar('--color-text-body'), marginRight: '-30px' }}
          >
            {moment.unix(currentEnd).utc().format('MMM Do hh:mm a')}
          </div>
        </div>
      </div>
      <Toolbar>
        <p style={{ marginBottom: '0' }}>Zoom: {numeral(maxDelta / currentDelta).format('0,0%')}</p>
        <Button onClick={handleReset}>Reset</Button>
      </Toolbar>
    </div>
  );
});

function ConversionTimeline({ original }: Readonly<IRow>): JSX.Element {
  return <ConversionTimelineSubComponent conversion={original} />;
}

export { ConversionTimeline };
