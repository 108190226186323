import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IConfig, IStatsListParams, TConversionStats } from '@feathr/blackbox';
import { CampaignClass, CampaignState, reportModuleLabels } from '@feathr/blackbox';
import { moment, useId } from '@feathr/hooks';
import { useStore } from '@feathr/report_components/state';

import type { IReportProps } from '../../types';
import ActivityCard from '../ActivityCard';
import ActivitySummaryCard from '../ActivitySummaryCard';
import AdPerformanceCard from '../AdPerformanceCard';
import BudgetCard from '../BudgetCard';
import ConversionTableCard from '../ConversionTableCard';
import EmailPerformanceCard from '../EmailPerformanceCard';
import LoadingMessage from '../LoadingMessage';
import { ROICard } from '../ROICard';

function EventReport({
  config = {
    attributionModel: 'full',
    includeActivitySummary: true,
    includeAdActivity: true,
    includeAdPerformance: true,
    includeConversionsTable: false,
    includeEmailActivity: true,
    includeEmailPerformance: true,
    includePageActivity: true,
    includeROI: false,
    includeSpendPerformance: true,
  } as IConfig,
  end,
  localUrl,
  hasGoals,
  modelId,
  start,
}: Readonly<IReportProps>): JSX.Element {
  const { t } = useTranslation();
  const listId = useId();
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { AggregatedStats, Events, Campaigns, Targetings, Goals, Flights, Stats } =
          useStore();
        const event = Events.get(modelId);
        let eventDailyStats = Stats.newListResponse();
        const campaigns = Campaigns.list({
          filters: {
            _parent: modelId,
            is_archived__ne: true,
            state__ne: CampaignState.Draft,
            _cls__ne: CampaignClass.PinpointPartnerMessage,
          },
        });
        const flights = Flights.list({
          filters: {
            event_id: modelId,
            is_archived__ne: true,
          },
        });
        if (!event.isPending) {
          const fetchParams: Partial<IStatsListParams> = {
            filters: {
              metadata__date__gte: start,
              metadata__date__lte: end,
              metadata__obj_id: modelId,
            },
            model: 'event',
            ordering: ['metadata.date'],
            pagination: {
              page_size: 200,
            },
          };

          if (!config.includeROI) {
            fetchParams.exclude = [
              'conversions.first_touch.segments_breakdown',
              'conversions.last_touch.segments_breakdown',
              'conversions.full.segments_breakdown',
              'conversions.linear.segments_breakdown',
              'conversions.positional.segments_breakdown',
              'conversions.temporal.segments_breakdown',
            ];
          }
          eventDailyStats = Stats.list(fetchParams, {}, listId);
          if (eventDailyStats.pagination.page < eventDailyStats.pagination.pages - 1) {
            eventDailyStats.fetchMore();
          }
        }
        let goals = Goals.newListResponse();
        let targetings = Targetings.newListResponse();
        if (!campaigns.isPending && !flights.isPending) {
          const flightlessCampaigns = campaigns.models.filter((cpn) => !cpn.get('flight'));
          goals = Goals.list({
            filters: {
              _parent__in: flights.models
                .map((f) => f.id)
                .concat(flightlessCampaigns.map((cpn) => cpn.id)),
              is_archived__ne: true,
            },
          });
          targetings = Targetings.list({
            filters: {
              _parent__in: campaigns.models.map((cpn) => cpn.id),
              is_archived__ne: true,
            },
          });
        }

        const aggregatedStats = AggregatedStats.aggregate({
          filters: {
            metadata__date__gte: start,
            metadata__date__lte: end,
            metadata__obj_id: modelId,
          },
          model: 'event',
        });

        const pending =
          event.isPending ||
          eventDailyStats.isPending ||
          campaigns.isPending ||
          goals.isPending ||
          targetings.isPending ||
          aggregatedStats.isPending;
        if (pending) {
          return <LoadingMessage />;
        }
        const budget = campaigns.models
          .filter((cpn) => {
            const reportStartMoment = moment.utc(start, moment.ISO_8601);
            const reportEndMoment = moment.utc(end, moment.ISO_8601);
            const dateStartMoment = moment.utc(cpn.get('date_start'), moment.ISO_8601);
            return (
              dateStartMoment.isSameOrAfter(reportStartMoment) &&
              dateStartMoment.isBefore(reportEndMoment)
            );
          })
          .reduce((acc, cpn) => {
            const exposureSettings = cpn.get('exposure_settings');
            if (exposureSettings.target_type === 'fixed_budget') {
              return acc + (exposureSettings.target_value || 0);
            }
            return acc;
          }, 0);

        const numConversions = eventDailyStats.models.reduce((acc, stat) => {
          const conversions = stat.get('conversions', {} as TConversionStats);
          return acc + (conversions[config.attributionModel]?.num || 0);
        }, 0);
        return (
          <>
            {config.includeROI && hasGoals && (
              <ROICard
                attributionModel={config.attributionModel}
                campaigns={campaigns.models.map((cpn) => cpn.toJS())}
                dailyStats={eventDailyStats.models}
                end={end}
                flights={flights.models.map((f) => f.toJS())}
                goals={goals.models.map((goal) => goal.toJS())}
                start={start}
              />
            )}
            {config.includeActivitySummary && (
              <ActivitySummaryCard
                aggregatedStats={aggregatedStats}
                isEventOrFlight={true}
                reachTooltipText={t(
                  'People who viewed marketing material from campaigns in this project.',
                )}
                uniqueClicksTooltipText={t(
                  'Unique people who clicked on marketing material from campaigns in this project.',
                )}
              />
            )}
            {config.includeAdPerformance && (
              <AdPerformanceCard
                aggregatedStats={aggregatedStats}
                clicksTooltip={t('Total clicks across all ad campaigns in this project.')}
                viewsTooltip={t('Total views across all ad campaigns in this project.')}
              />
            )}
            {config.includeEmailPerformance && (
              <EmailPerformanceCard aggregatedStats={aggregatedStats} reportType={'Project'} />
            )}
            {config.includeSpendPerformance && (
              <BudgetCard
                aggregatedStats={aggregatedStats}
                budget={budget}
                conversions={numConversions}
                spendTooltip={t(
                  'Total spend for all campaigns that are in this project and within the report date window.',
                )}
              />
            )}
            <div style={{ marginBottom: '10px' }}>
              {config.includeAdActivity && (
                <ActivityCard
                  dailyStats={eventDailyStats.models}
                  label={reportModuleLabels.includeAdActivity}
                  lines={[
                    { name: 'Views', flavors: [{ key: 'ad_view' }] },
                    { name: 'Clicks', flavors: [{ key: 'ad_click' }], yAxisId: 'right' },
                  ]}
                />
              )}
              {config.includeEmailActivity && (
                <ActivityCard
                  dailyStats={eventDailyStats.models}
                  label={reportModuleLabels.includeEmailActivity}
                  lines={[
                    {
                      name: 'Views',
                      flavors: [{ key: 'email_view' }, { key: 'pinpoint_tracked_email_open' }],
                    },
                    {
                      name: 'Clicks',
                      flavors: [
                        { key: 'email_link_click' },
                        { key: 'pinpoint_tracked_email_click' },
                      ],
                      yAxisId: 'right',
                    },
                  ]}
                />
              )}
              {config.includePageActivity && (
                <ActivityCard
                  dailyStats={eventDailyStats.models}
                  label={reportModuleLabels.includePageActivity}
                  lines={[
                    { name: 'Views', flavors: [{ key: 'page_view' }] },
                    { name: 'Clicks', flavors: [{ key: 'page_link_click' }], yAxisId: 'right' },
                  ]}
                />
              )}
              {config.includeConversionsTable && hasGoals && (
                <ConversionTableCard
                  attributionModel={config.attributionModel}
                  campaigns={campaigns.models}
                  end={end}
                  goals={goals.models.map((goal) => goal.toJS())}
                  localUrl={localUrl}
                  model={event}
                  start={start}
                  targetings={targetings.models}
                />
              )}
            </div>
          </>
        );
      }}
    </Observer>
  );
}

export default EventReport;
