import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Button, Skeleton, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import TemplatePreview from '@feathr/extender/components/TemplatePreview';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import * as styles from './TemplatePage.css';

function TemplatePage(): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const { templateId } = useParams<{ templateId: string; tab: string }>();
  const { Templates } = useStore();
  const template = Templates.get(templateId);
  const isReadOnly = template.get('default') || template.get('read_only');

  const actions = (
    <Toolbar>
      <Button
        disabled={isReadOnly}
        id={'edit'}
        link={localUrl(template.getItemUrl(!isReadOnly ? 'edit' : undefined))}
      >
        {t('Edit')}
      </Button>
    </Toolbar>
  );

  return (
    <Page actions={actions} title={template.name}>
      {template.isPending ? (
        <Skeleton paragraphs={3} title={true} />
      ) : (
        <div className={styles.preview}>
          <TemplatePreview template={template} visible={true} />
        </div>
      )}
    </Page>
  );
}

export default observer(TemplatePage);
