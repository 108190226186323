import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Campaign, IPredicate } from '@feathr/blackbox';
import { Button, toast } from '@feathr/components';
import { EReportFlavors, useStore } from '@feathr/report_components/state';

import { flavorToInteractionMap } from './CampaignEngagementCard';

interface IProps {
  campaign: Campaign;
  flavor: EReportFlavors;
  localUrl: ((url: string) => string) | undefined;
  group: IPredicate[];
}

function CreateGroupButton({ campaign, flavor, localUrl, group }: Readonly<IProps>): JSX.Element {
  const { Segments } = useStore();
  const { t } = useTranslation();
  const flavorIsUnsubscribe = flavor === EReportFlavors.email_preferences_update;

  async function handleCreateGroup(): Promise<void> {
    const newGroup = Segments.create({
      name: `${campaign.name} -- ${flavorToInteractionMap(t)[flavor]}`,
      lookback_mode: 'unbounded',
      predicates: [{ group }],
    });

    const response = await Segments.add(newGroup);
    if (response.isErrored) {
      toast(
        t('There was an error creating your group: {{error}}', {
          error: response.error?.message,
        }),
        {
          type: ToastType.ERROR,
        },
      );
      return;
    }
    toast(t('Your group was successfully created.'), { type: ToastType.SUCCESS });
    window.open(localUrl!(response.getItemUrl()), '_blank');
  }

  return (
    <Button
      disabled={flavorIsUnsubscribe}
      name={'create-group-button'}
      onClick={handleCreateGroup}
      target={'_blank'}
      tooltip={flavorIsUnsubscribe ? t("You can't create a group based on this filter.") : null}
    >
      {t('Create group')}
    </Button>
  );
}

export default observer(CreateGroupButton);
