import { computed, makeObservable } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel } from '@feathr/rachis';

interface IFormStats {
  num_crumbs: number;
  num_views: number;
  num_submissions: number;
  submission_rate: number;
}

export enum EFormState {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export interface IForm extends IBaseAttributes {
  account: string;
  name: string;
  state: EFormState;
  content_json: string;
  event: string;
  total_stats: IFormStats;
  version: number;
  date_last_modified: string;
}

export class Form extends DisplayModel<IForm> {
  public readonly className = 'Form';

  public get constraints(): TConstraints<IForm> {
    return {
      name: {
        presence: {
          allowEmpty: false,
        },
      },
    };
  }

  constructor(attributes: Partial<IForm> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    const path = window.location.pathname.split('/');
    const eventId = path[3];

    return concatPath(
      `/projects/${this.get('event') ?? eventId}/content/forms/${this.id}`,
      pathSuffix,
    );
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Form';
  }
}

export class Forms extends Collection<Form> {
  public getClassName(): string {
    return 'forms';
  }

  public getModel(attributes: Partial<IForm>): Form {
    return new Form(attributes);
  }
}
