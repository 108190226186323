import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Input } from '@feathr/components';

import type { IField } from '../FormEditor.utils';
import Field from './Field';

import * as styles from './FormFields.css';

interface IProps {
  fields: IField[];
}

function FormFields({ fields }: IProps): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');

  function handleSearch(value?: string): void {
    setSearch(value || '');
  }

  return (
    <>
      <Input
        isClearable={true}
        onChange={handleSearch}
        placeholder={t('Search by name...')}
        prefix={<Icon icon={faSearch} />}
        type={'text'}
        value={search}
        wrapperClassName={styles.input}
      />

      <div className={styles.container}>
        {fields.map((field) => (
          <Field field={field} key={field.id} />
        ))}
      </div>
    </>
  );
}

export default FormFields;
