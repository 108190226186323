import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import type { JSX } from 'react';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { IPredicate } from '@feathr/blackbox';
import { FieldCollection, Segment } from '@feathr/blackbox';
import { Button, Tooltip } from '@feathr/components';
import { useAccount, useStore } from '@feathr/extender/state';
import type { IBaseAttributes, Model } from '@feathr/rachis';

import Glue from '../../Glue';
import type { IAttrOption } from '../PredicateInputs';
import PredicateInputs from '../PredicateInputs';

import styles from './FilterChip.css';

import defaultBreadcrumbFields from '@feathr/blackbox/fixtures/defaultBreadcrumbFields.json';
import defaultPersonFields from '@feathr/blackbox/fixtures/defaultPersonFields.json';

interface IProps {
  filterContext?:
    | 'campaign'
    | 'flight'
    | 'segment'
    | 'campaignGoal'
    | 'flightGoal'
    | 'facebookCampaign'
    | 'pinpoint';
  disabled?: boolean;
  onClickRemove?: () => void;
  predicate: IPredicate;
  model: Model<IBaseAttributes>;
  excludeIds?: string[];
  triggers?: IPredicate[];
}

// delete this and its css in #3595
function FilterChip({
  filterContext = 'segment',
  disabled = false,
  onClickRemove,
  predicate,
  model,
  excludeIds,
  triggers,
}: IProps): JSX.Element {
  const account = useAccount();
  const { CustomFields } = useStore();
  const { t } = useTranslation();

  const customFieldHelpText = t(
    'This is a custom field that has been added to your Feathr database.',
  );

  const isSegment = model instanceof Segment;

  useEffect(
    () =>
      reaction(
        () => JSON.stringify(Object.values(predicate)),
        () => {
          // (segment.predicates || campaign.actions) is dirty if the predicate changes.
          isSegment ? model.setAttributeDirty('predicates') : model.setAttributeDirty('actions');
        },
      ),
    [predicate, model, isSegment],
  );

  const GATED_BREADCRUMB_ATTRS = [
    {
      id: 'p_id',
      should_show: account && account.isFalcon,
    },
    {
      id: 'q_id',
      should_show: isSegment ? !!model.get('read_only') : false,
    },
    {
      id: 'msg',
      should_show: isSegment ? !!model.get('read_only') : false,
    },
  ];

  function shouldRenderItem(item: any): boolean {
    const gatedAttr = GATED_BREADCRUMB_ATTRS.find((attr) => attr.id === item.id);
    if (gatedAttr) {
      return gatedAttr.should_show;
    }

    if (filterContext === 'pinpoint') {
      /**
       * There are certain activity predicates we don't want used
       * in email campaigns.
       */
      return !['brow', 'pform', 'd_c', 'isp'].includes(item.id);
    }

    return true;
  }

  function handleClickAdd(): void {
    const attrAgainst = predicate.kind === 'activity' ? 'loc_url' : 'date_last_seen';
    const attrType = predicate.kind === 'activity' ? 'string' : 'date';
    if (predicate.group && predicate.group.length) {
      predicate.group.push({
        kind: predicate.kind,
        attr_against: attrAgainst,
        attr_type: attrType,
        comparison: 'eq',
        value: '',
        unit: 'days',
      });
    } else {
      const { group, ...predicateConfig } = predicate;
      predicate.group = [
        predicateConfig,
        {
          kind: predicate.kind,
          attr_against: attrAgainst,
          attr_type: attrType,
          comparison: 'eq',
          value: '',
          unit: 'days',
        },
      ];
    }
  }

  let personAttrOptions: IAttrOption[] = defaultPersonFields;
  let breadcrumbAttrOptions: IAttrOption[] = [];
  let personAttrIsLoading = false;

  const customPersonFields = CustomFields.list({
    filters: {
      collection: FieldCollection.Person,
    },
    pagination: {
      page: 0,
      page_size: 1000,
    },
  });
  personAttrIsLoading = customPersonFields.isPending;
  if (!personAttrIsLoading) {
    personAttrOptions = [
      ...personAttrOptions,
      ...customPersonFields.models.map(
        (field) =>
          ({
            helpText: field.get('description', customFieldHelpText),
            id: `custom.${field.get('f_key')}`,
            kind: field.get('collection'),
            name: field.get('u_key'),
            type: field.get('data_type'),
          }) as IAttrOption,
      ),
    ];
  }

  const customBreadcrumbFields = CustomFields.list({
    filters: {
      collection: FieldCollection.Breadcrumb,
    },
  });
  if (!customBreadcrumbFields.isPending) {
    breadcrumbAttrOptions = [
      ...defaultBreadcrumbFields.filter(shouldRenderItem),
      ...customBreadcrumbFields.models.map((field) => ({
        helpText: field.get('description', customFieldHelpText),
        id: `cust_params.${field.get('f_key')}`,
        kind: field.get('collection'),
        name: field.get('u_key'),
        type: field.get('data_type'),
      })),
    ];
  }

  const subtype = model.get('subtype');
  const mode = model.get('mode');
  const isLoading = personAttrIsLoading || customBreadcrumbFields.isPending;
  const personAttrs = ['facebook', 'Goal'].some((str) => filterContext.includes(str))
    ? []
    : personAttrOptions;
  const handleRemoveRule = (index: number) => (): void => {
    predicate.group!.splice(index, 1);
  };

  return (
    <div className={styles.root}>
      <div className={styles.predicateGroup}>
        {predicate.group && predicate.group.length ? (
          predicate.group.map((pred, index) => {
            const uuid = nanoid(12);
            const lastItemIndex = predicate.group!.length - 1;

            return (
              <Fragment key={uuid}>
                <div className={styles.predicateWrapper}>
                  <div className={styles.predicate}>
                    <PredicateInputs
                      attrOptionsIsLoading={isLoading}
                      breadcrumbAttrOptions={breadcrumbAttrOptions}
                      disabled={disabled}
                      excludeIds={excludeIds}
                      filterContext={filterContext}
                      group={true}
                      kind={predicate.kind}
                      personAttrOptions={personAttrs}
                      predicate={pred}
                      subtype={subtype}
                    />
                  </div>
                  <div>
                    <Tooltip title={t('Remove filter rule')}>
                      <Button
                        className={styles.singleClose}
                        name={'remove_filter_rule'}
                        onClick={handleRemoveRule(index)}
                        type={'icon'}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <Glue isLastItem={index === lastItemIndex} mode={mode} t={t} />
              </Fragment>
            );
          })
        ) : (
          <div className={styles.predicate}>
            <PredicateInputs
              attrOptionsIsLoading={isLoading}
              breadcrumbAttrOptions={breadcrumbAttrOptions}
              disabled={disabled}
              excludeIds={excludeIds}
              filterContext={filterContext}
              kind={predicate.kind}
              mode={mode}
              personAttrOptions={personAttrs}
              predicate={predicate}
              subtype={subtype}
              triggers={triggers}
            />
          </div>
        )}
      </div>
      {!disabled && (
        <span className={styles.actions}>
          {isSegment && (
            <Tooltip title={t('Add a rule to this filter')}>
              <Button name={'add_rule'} onClick={handleClickAdd} type={'icon'}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Tooltip>
          )}
          {onClickRemove && (
            <Tooltip title={t('Remove filter')}>
              <Button name={'remove_filter'} onClick={onClickRemove} type={'icon'}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Tooltip>
          )}
        </span>
      )}
    </div>
  );
}

export default observer(FilterChip);
